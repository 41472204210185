import React from 'react';
import './loaders.css'

/*
* animations = ['dot-pulse', 'dot-falling', 'dot-typing']
* */
function VTRLoader({animation}) {
    return (
        <div className="stage">
            <div className={animation} />
        </div>
    )
}

export {VTRLoader}