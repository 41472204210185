import React from 'react'
import './on-vous-emmene.css'
import {connect} from "react-redux";
import { MDBRow, MDBCol, MDBCard } from "mdbreact";
import LazyLoad from 'react-lazy-load';
import data from './on-vous-emmene.json'
import history from "../../../App/routes/history";

const mapStateToProps = (state) => {
  return { language : state.language }
}

// eslint-disable-next-line
function importAll(r) {
  let images = {};
  // eslint-disable-next-line
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

// eslint-disable-next-line
// const images = importAll(require.context('../../../assets/img', false, /\.(png|jpe?g|svg)$/));

class OnVousEmmene extends React.Component {
  redirect = (event, link) => {
    event.stopPropagation();
    history.push(link);
    window.location.reload();
  }

  loadOnVousEmmeneItem = () => {
    let details = []
    let i = 0

    for (let item of data.item) {
      details.push(
        <MDBCol
        key={`on-vous-emmene-card-${i}`} 
        xs="1" lg="2" xl="2" md="5" 
        className="mt-3 mb-lg-0 mb-4">
          <MDBCard collection className="rounded-corners z-depth-1-half" onClick={(e) => this.redirect(e, item.link)}>
            <div className="rounded-corners view zoom">
            <LazyLoad debounce={false} throttle={250}>
              <img src={`https://vtr-voyages-images.s3.eu-west-3.amazonaws.com/autre/homepage/${item.img}`} className="img-fluid-on-vous-emmene rounded-corners" alt={item.alt}/>
            </LazyLoad>
            <noscript>
            <img src={`https://vtr-voyages-images.s3.eu-west-3.amazonaws.com/autre/homepage/${item.img}`} className="img-fluid-on-vous-emmene" alt={item.alt}/>
            </noscript>
              <div className="d-flex flex-column justify-content-center align-items-center stripe dark">
                <a href={item.link}>
                  <p className="text-white">
                    {this.props.language.langue === "fr" ? item.title.fr : item.title.en}
                  </p>
                </a>
              </div>
            </div>
          </MDBCard>
        </MDBCol>
      )
      i++
    }
    return (
      <div id="onVousEmmene" className="onVousEmmene">
      <br/>
      <MDBRow className='justify-content-between'>
        {details}
      </MDBRow>
      </div>
    )
  }

  render() {
    return this.loadOnVousEmmeneItem()
  }
}

export default connect(mapStateToProps)(OnVousEmmene)
