import React, {useState} from 'react';
import {MDBBtn, MDBPopover, MDBPopoverBody} from "mdbreact";

function Counter({label, info, value, onChange}) {
    const add = () => {
        if (value === 19) {
            return;
        } else {
            onChange(value+1);
        }
    }

    const sub = () => {
        if (value === 0) {
            return;
        } else {
            onChange(value-1);
        }
    }

    const formatValue = (val) => {
        return val.toString().length === 1? `0${value}` : value;
    }

    return (
        <div className="d-flex justify-content-between align-items-center">
            <span className="counterLabel">
                <h5 className="font-weight-bold m-0">{label}</h5>
                <p className="text-grey m-0">{info}</p>
            </span>
            <span className="d-flex align-items-center counterBtn">
                {/*<MDBBtn className="bg-white shadow-none rounded" color="white" disabled={value === 0}
                        onClick={() => sub()}
                >
                    <i className={`fas fa-${value===0? 'ban' : 'minus-circle'} fa-lg text-grey`}/>
                </MDBBtn>*/}
                <i className={`p-2 fas fa-${value===0? 'ban' : 'minus-circle'} fa-lg text-grey`}
                   style={{cursor: 'pointer'}} onClick={() => sub()}
                />
                <h5 className="font-weight-light pl-3 pr-3 m-0">{formatValue(value)}</h5>
                {/*<MDBBtn className="bg-white shadow-none rounded" color="white" disabled={value >= 19}
                        onClick={() => add()}
                >
                    <i className={`fas fa-${value===19? 'ban' : 'plus-circle'} fa-lg text-grey`}/>
                </MDBBtn>*/}
                <i className={`p-2 fas fa-${value===19? 'ban' : 'plus-circle'} fa-lg text-grey`}
                   style={{cursor: 'pointer'}} onClick={() => add()}
                />
            </span>
        </div>
    );
}

function ParticipantCounter({participants, update}) {
    const [collapsed, setCollapsed] = useState(true);

    return (
        <div>
            <MDBPopover placement="bottom" popover clickable id="popperPaxCounter">
                <MDBBtn flat className="ml-0 p-0  w-100 buttonPaxCounter shadow-none bg-white" color="light"
                        onClick={() => setCollapsed(!collapsed)}
                >
                    <span className="d-flex align-items-center w-100">
                        <i className="ml-2 fa-lg fas fa-user" style={{color: '#009ee0'}} />&nbsp;&nbsp;
                        <p className="w-100 m-0 text-left sizePaxCounter" style={{fontFamily: "Helvetica, sans-serif", fontWeight: "300"}}>
                            {participants.adult} Adultes - {participants.child} Enfants
                        </p>
                    </span>
                </MDBBtn>
                <div className="w-100">
                    <MDBPopoverBody className="">
                        <Counter label="Adultes" info="18 ans et plus" value={participants.adult}
                                 onChange={(val) => update('adult', val)}
                        />
                        <hr className="w-100"/>
                        <Counter label="Enfants" info="17 ans et moins" value={participants.child}
                                 onChange={(val) => update('child', val)}
                        />
                    </MDBPopoverBody>
                </div>
            </MDBPopover>
        </div>
    );
}

export {ParticipantCounter};