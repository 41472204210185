import React from 'react'
import './derniere-minutes.css'
import {connect} from "react-redux";
import data from './derniere-minutes.json'
import LazyLoad from 'react-lazy-load';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from 'mdbreact';

const mapStateToProps = (state) => {
  return {
    language : state.language,
    home : state.home
  }
}

class LastMinutes extends React.Component {
  state = {
    activeItem: "cetteSemaine"
  };

  toggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  };

  loadSejour(specificData) {
    let array = []
    let i = 0

    if (!specificData) return
    if (specificData.length === 0) return;
    for (let offre of specificData) {
      array.push(
        <MDBCard key={i}>
        <MDBCardBody className="CardClasses">
        <MDBRow>
          <MDBCol xl="10">
            <a href={offre._source.link}><p className="TitleBold">{this.props.language.langue === "fr" ? offre._source.title.fr : offre._source.title.en}</p></a>
          </MDBCol>
          <MDBCol>
            <p className="promoPriceDerniereMinutes">{Math.round(offre._source.min_price)}€</p>
            <p className="price">{Math.round(offre._source.promo_price)}€</p>
          </MDBCol>
        </MDBRow>
        <p className="descriptif">{offre._source.localisation.ville}</p>
        </MDBCardBody>
        </MDBCard>
      )
      i++
    }
    return array
  }

  render() {
      return (
        <div id="derniere-minutes" className="setSpacing mb-5">
          <span className="Title">{this.props.language.langue === "fr" ? "Dernières minutes" : "Last minutes"}</span>
          <hr/>
          <MDBRow>
          <MDBCol xl="5">
            <MDBNav defaultActiveKey="cetteSemaine" id="tab-dernieres-minutes">
              <MDBNavItem>
                <MDBNavLink className="cetteSemaine" link to="#" active={this.state.activeItem === "cetteSemaine"} onClick={this.toggle("cetteSemaine")} role="tab" >
                {this.props.language.langue === "fr" ? "Cette semaine" : "This week"}
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink className="semaineProchaine" link to="#" active={this.state.activeItem === "semaineProchaine"} onClick={this.toggle("semaineProchaine")} role="tab" >
                {this.props.language.langue === "fr" ? "Semaine prochaine" : "Next week"}
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink className="deuxSemaines" link to="#" active={this.state.activeItem === "deuxSemaines"} onClick={this.toggle("deuxSemaines")} role="tab" >
                {this.props.language.langue === "fr" ? "+15 jours" : "+15 days"}
                </MDBNavLink>
              </MDBNavItem>


                {/* <MDBTabPane eventKey="cetteSemaine" className="cetteSemaine" title={this.props.language.langue === "fr" ? "Cette semaine" : "This week"}>
                  {this.loadSejour(this.props.home.derniereMinutes.this_week)}
                </MDBTabPane>
                <MDBTabPane eventKey="semaineProchaine" title={this.props.language.langue === "fr" ? "Semaine prochaine" : "Next week"}>
                  {this.loadSejour(this.props.home.derniereMinutes.next_week)}
                </MDBTabPane>
                <MDBTabPane eventKey="deuxSemaines" title={this.props.language.langue === "fr" ? "+15 jours" : "+15 days"}>
                {this.loadSejour(this.props.home.derniereMinutes.far_week)}
                </MDBTabPane> */}


            </MDBNav>
            <MDBTabContent activeItem={this.state.activeItem} >
              <MDBTabPane tabId="cetteSemaine" role="tabpanel">
              {this.loadSejour(this.props.home.derniereMinutes.this_week)}
              </MDBTabPane>

              <MDBTabPane tabId="semaineProchaine" role="tabpanel">
              {this.loadSejour(this.props.home.derniereMinutes.next_week)}
              </MDBTabPane>

              <MDBTabPane tabId="deuxSemaines" role="tabpanel">
              {this.loadSejour(this.props.home.derniereMinutes.far_week)}
              </MDBTabPane>
            </MDBTabContent>
          </MDBCol>


          <MDBCol className="notResponsive" xl="4"><a href={data.longImageLink}>
            <div className="specialContainer">
            <LazyLoad offsetVertical={750} debounce={false} throttle={250}>
              <img alt="img" className="w-100 coverImage" src={data.longImage}/>
            </LazyLoad>
            <noscript>
            <style>{`.lazyload-placeholder { display: none; }`}</style>
            <img alt="img" className="w-100 coverImage" src={data.longImage}/>
            </noscript>
              <div className="vignette">{this.props.language.langue === "fr" ? data.longImageText.fr : data.longImageText.en }</div>
              <div className="circle">{data.longImageValue}</div>
            </div></a>
          </MDBCol>
          <MDBCol className="notResponsive specialContainer" xl="3">
          <LazyLoad offsetVertical={750} debounce={false} throttle={250}>
            <a href={data.firstShortImageLink}>
              <img className="w-100 setImageSpacing coverImage" alt="img" src={data.firstShortImage}/>
            <div className="vignetteSmallImage">{this.props.language.langue === "fr" ? data.firstShortImageText.fr : data.firstShortImageText.en }</div></a>
            </LazyLoad>
            <noscript>
            <style>{`.lazyload-placeholder { display: none; }`}</style>
            <a href={data.firstShortImageLink}>
              <img className="w-100 setImageSpacing coverImage" alt="img" src={data.firstShortImage}/>
            <div className="vignetteSmallImage">{this.props.language.langue === "fr" ? data.firstShortImageText.fr : data.firstShortImageText.en }</div></a>
            </noscript>
            <LazyLoad offsetVertical={750} debounce={false} throttle={250}>
            <a href={data.secondShortImageLink}><img className="w-100 coverImage" alt="img" src={data.secondShortImage}/>
            <div className="vignetteSmallImageSecond">{this.props.language.langue === "fr" ? data.secondShortImageText.fr : data.secondShortImageText.en }</div></a>
            </LazyLoad>
            <noscript>
            <style>{`.lazyload-placeholder { display: none; }`}</style>
            <a href={data.secondShortImageLink}><img className="w-100 coverImage" alt="img" src={data.secondShortImage}/>
            <div className="vignetteSmallImageSecond">{this.props.language.langue === "fr" ? data.secondShortImageText.fr : data.secondShortImageText.en }</div></a>
            </noscript>
          </MDBCol>
          </MDBRow>
        </div>
      )
    }
}

export default connect(mapStateToProps)(LastMinutes)
