import React from 'react'
import { connect } from 'react-redux'
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import history from '../../../App/routes/history'
import 'react-dates/initialize';
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { START_DATE, END_DATE } from 'react-dates/constants';
import moment from 'moment';

import 'moment/locale/fr'
import SuggestionVille from './suggestVille';
import {ParticipantCounter} from "./participantCounter";
import { isMobile } from 'react-device-detect';
import {VTRLoader} from "../../../components/animations/loaders";

const mapStateToProps = (state) => {
    return {
      menu : state.menu,
      language : state.language,
      sejour : state.sejour
    }
}

class NewSearch extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ville : "",
            activeItem: "0",
            redirect : false,
            participant: 0,
            participants : {adult: 0, child: 0},
            duree : "",
            data : {key : "", value : ""}
      }
    }

    componentDidMount() {
        let location = typeof document === "undefined" ? this.props.location : window.location.pathname

        location = location.split('/')
        //Active la bonne tab
        if (location[1] === "sejours-ski" && typeof location[2] === "undefined") {
            this.setState({activeItem : "1"})
        }
        else if (location[0] === "" && location[1] === "") {
            //verification home page
            this.setState({activeItem : "0"})
        }
        else if (location[1] === "sejours-france") {
            this.setState({activeItem : "2"})
        }
        else if (location[1] === "colonies-de-vacances") {
            this.setState({activeItem : "3"})
        }
        else if (location[1] === "sejours-sportifs") {
            this.setState({activeItem : "4"})
        }
        else if (location[1] === "campings") {
            this.setState({activeItem : "5"})
        }
        else {
            this.setState({activeItem : "0"})
        }

    }

    generateUrl = () => {

        const searchParams = new URLSearchParams(window.location.search)
        let url = "/recherche?" + searchParams


        if (this.state.data.value !== "") {
            url += this.state.data.key + this.state.data.value
        }

        if (this.state.participants.adult !== 0 || this.state.participants.child !== 0) {
            url += "&adulte=" + this.state.participants.adult + "&child=" + this.state.participants.child
        }
        else {
            //url += "&nbr_personnes[0]=" + this.state.participant
        }

        ///// DATE SEARCH /////
        let endDate = null
        let startDate = null

        if (!this.state.endDate && this.state.startDate){
            const date = this.state.startDate.toString()
            startDate = this.state.startDate
            endDate = moment(date).add('2', 'weeks')
        }

        if (!this.state.startDate && this.state.endDate){
            startDate = moment()
            endDate = this.state.endDate
        }

        if (this.state.endDate && this.state.startDate){
            console.log("ok")
            endDate = this.state.endDate
            startDate = this.state.startDate
        }

        if (startDate && endDate){
            let start = new Date(startDate)
            let end = new Date(endDate)
            console.log(start,end)

            start.setHours(0)
            end.setHours(0)

            url += "&event_date_from=" + start.getTime() + "&event_date_to=" + end.getTime()
        }

        ///// DATE SEARCH /////

        if (this.state.activeItem !== "0") {
            let univers = ""
            if (this.state.activeItem === "1") univers = "Ski"
            if (this.state.activeItem === "2") univers = "France"
            if (this.state.activeItem === "3") univers = "Colonies%20de%20vacances"
            if (this.state.activeItem === "4") univers = "Sportifs"
            if (this.state.activeItem === "5") univers = "Campings"
            url += "&Sejour[0]=" + univers
        }

        let query = url.split('?')[1];
        if (query.charAt(0) === '&') {
            query = query.substr(0, 0) + '' + query.substr(1);
            url = url.split('?')[0] + '?' + query
        }

        this.setState({redirect : true, redirectUrl : url})
    }

    isDayBlocked = momentDate => {
        return (momentDate.format('d') !== "6" && momentDate.format('d') !== "0")
    }

    onFocusChange = (focusedInput) => {
        this.setState({ focusedInput });
        if(focusedInput === START_DATE) {
          this.setState({ endDate: null });
        }
    }

    renderForm = () => {
        const {participants, focusedInput, startDate} = this.state;
        let maximumNights = 16;
        let maximumEndDate = moment().add(maximumNights, 'days');
        let condition;
        const direction = isMobile ?  'vertical' :  'horizontal';

        if(startDate){
            maximumEndDate = startDate.clone().add(maximumNights, 'days')
        }

        if(focusedInput === END_DATE) {
            condition = (day) => !isInclusivelyAfterDay(day, moment()) || isInclusivelyAfterDay(day, maximumEndDate);
        }

        if(focusedInput === START_DATE) {
            condition = (day) => !isInclusivelyAfterDay(day, moment());
        }

        return (
            <div className="containerNewSearch">
                {/*<div className="d-flex justify-content-between p-4">*/}
                {/*    {['dot-pulse', 'dot-falling', 'dot-typing'].map((animation, idx) => (*/}
                {/*        <span className="d-flex flex-column justify-content-center align-items-center">*/}
                {/*            <MDBBtn className="d-flex justify-content-center align-items-center w-25 rounded" color="info">*/}
                {/*                <VTRLoader animation={animation} />*/}
                {/*            </MDBBtn>*/}
                {/*            <h4>{idx+1}</h4>*/}
                {/*        </span>*/}
                {/*    ))}*/}
                {/*</div>*/}
                <MDBRow center className="rowNewSearch">
                    
                   <MDBCol className="pl-0 pr-0" xs="12" sm="12" md="12" lg="3" xl="3">
                       <SuggestionVille updateData={(data) => this.setState({data : data})}/>
                   </MDBCol>

                   
                    <MDBCol className="pl-0 pr-0 DateRangePicker" xs="12" sm="12" md="12" lg="4" xl="4" >
                        <DateRangePicker
                            hideKeyboardShortcutsPanel={true}
                            startDatePlaceholderText={"Départ"}
                            endDatePlaceholderText={"Retour"}
                            minimumNights={7}
                            isAnimating={true}
                            startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                            startDateId="startDateRecherche" // PropTypes.string.isRequired,
                            endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                            endDateId="endDateRecherche" // PropTypes.string.isRequired,
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                            keepOpenOnDateSelect={true}
                            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                            regular
                            withPortal={isMobile}
                            disableScroll={true}
                            orientation={direction}
                            isOutsideRange={condition}
                            readOnly
                            customInputIcon={<i className="far fa-lg fa-calendar-alt" />}
                            isDayBlocked={momentDate => { return momentDate.format('d') === "6" ? false : momentDate.format('d') === "0" ? false : true }}
                        />
                    </MDBCol>

                    <MDBCol className="pl-0 pr-0" xs="12" sm="12" md="12" lg="3" xl="3">
                        <ParticipantCounter participants={participants}
                                            update={(key, value) => {
                                                let _participants = {...participants, [key]: value};
                                                this.setState({participants: _participants})
                                            }}
                        />
                    </MDBCol>


                    <MDBCol  xs="12" sm="12" md="12"  lg="2" xl="2" className="pl-0 pr-0 speacialStyleForMobile">
                        <MDBBtn className="d-flex justify-content-center align-items-center w-100 m-0 p-0 ButtonSearch shadow-none text-white font-weight-bold rounded" color="info"
                                onClick={this.generateUrl} disabled={this.state.redirect}
                        >
                            {this.state.redirect? <VTRLoader animation="dot-pulse" /> : <i className="fas fa-search fa-md" />}&nbsp;&nbsp;
                            {this.state.redirect? null : this.props.language.langue === "fr" ? "Rechercher" : "Search"}
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }

    render () {
        if (this.state.redirect) {
            history.push(window.location.path)
            window.location.assign(this.state.redirectUrl);
        }
        return (
            <div id="newSearch" className={typeof this.props.page !== "undefined" ? "newSearchPage" : "newSearch" }>
                {this.renderForm()}
            </div>
        )
    }
}

export default connect(mapStateToProps)(NewSearch)