import React from 'react'
import {MDBRow, MDBCol} from "mdbreact";
import './features.css'
import { connect } from 'react-redux'
import { isMobile } from "react-device-detect";
import '../../../assets/vtr-theme.css';

const mapStateToProps = (state) => {
  return { language : state.language }
}

class Features extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      features: [
          {fr: "Voyagiste depuis 2001", en: "Tour operator since 2001", icon: 'plane', far: false},
          {fr: "Paiement 100% sécurisé", en: "100% Secure payment", icon: 'lock', far: false},
          {fr: "Devis gratuit", en: "Free quotation", icon: 'check', far: false},
          {fr: "Facilités de paiement", en: "Payment facilities", icon: 'thumbs-up', far: false},
          {fr: "Un conseiller unique", en: "A single advisor", icon: 'headset', far: false},
          {fr: "9 clients sur 10 satisfaits", en: "9 out of 10 satisfied clients", icon: 'smile-wink', far: false},
      ]
    }
  }

  render() {
    if (isMobile || this.props.language.mobile) return <div></div>
    return (
      <section className="text-center setColorBackground d-flex justify-content-center">
        <MDBRow className="m-0 p-2 d-flex container">
          {this.state.features.map(feature => (
              <MDBCol md="2">
                <div className="d-flex flex-column  justify-content-start h-100 p-2 pt-4 pb-4">
                  <div className="d-flex justify-content-center align-items-center mb-2">
                    <span className="d-flex justify-content-center align-items-center mb-2 shadow-sm"
                          style={{width: '86px', height: '86px', border: '2px solid #f0f0f0', borderRadius: '100%'}}>
                      <i className={`fa ${feature.far? 'far' : ''} fa-${feature.icon} fa-3x text-white p-2`} />
                    </span>
                  </div>
                  <p className="setSizeFeaturesFont text-center text-white m-0">
                    {this.props.language.langue === "fr" ? feature.fr : feature.en}
                  </p>
                </div>
              </MDBCol>
          ))}
        </MDBRow>
      </section>
    )
  }
}

export default connect(mapStateToProps)(Features)
