import React from 'react'
import DocumentMeta from 'react-document-meta';
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return { language : state.language }
}

class Meta extends React.Component {
  render() {    
    let location = ""
    if (typeof document === "undefined") {
      location = this.props.location.split(/[?#]/)[0]

      if (this.props.language.langue === "fr") {
        location = "https://www.vtr-voyages.fr" + location
      }
      else {
        location = "https://www.vtr-voyages.com" + location
      }
    }
    else {
      location = this.props.location.pathname.split(/[?#]/)[0]

      if (this.props.language.langue === "fr") {
        location = "https://www.vtr-voyages.fr" + location
      }
      else {
        location = "https://www.vtr-voyages.com" + location
      }
    }

    const meta = {
      title: this.props.title === "" ? this.props.language.langue === "fr" ? "VTR Voyages - vacances au soleil, à la mer, séjours de ski à la montagne" : "VTR Voyages - Travel Agency : ski holidays, sun and many other activites" : this.props.title,
      description: this.props.description === "" ? this.props.language.langue === "fr" ? "Trouvez un séjour au soleil, au ski ou sportif pour vos vacances en famille, en groupe (CE, étudiant, colonie pour enfants et ados) en France ou à l&#039;étranger" : "Holidays in France and oll over the world, ski packages, sports events, summer camps, customized trip for groups, students or professionals +33 4 67 58 36 36" : this.props.description,
      canonical: location,
      meta: {
        charSet: 'utf-8',
        name: {
          keywords: 'VTR Voyages, sejours ski, ski tout compris'
        },
        itemProp: {
          name: this.props.title === "" ? this.props.language.langue === "fr" ? "VTR Voyages - vacances au soleil, à la mer, séjours de ski à la montagne" : "VTR Voyages - Travel Agency : ski holidays, sun and many other activites" : this.props.title,
          description: this.props.description === "" ? this.props.language.langue === "fr" ? "Trouvez un séjour au soleil, au ski ou sportif pour vos vacances en famille, en groupe (CE, étudiant, colonie pour enfants et ados) en France ou à l&#039;étranger" : "Holidays in France and oll over the world, ski packages, sports events, summer camps, customized trip for groups, students or professionals +33 4 67 58 36 36" : this.props.description,
        },
        property: {
            'og:url' : location,
            'og:title': this.props.title === "" ? this.props.language.langue === "fr" ? "VTR Voyages - vacances au soleil, à la mer, séjours de ski à la montagne" : "VTR Voyages - Travel Agency : ski holidays, sun and many other activites" : this.props.title,
            'og:type': 'website',
            'og:description': this.props.description === "" ? this.props.language.langue === "fr" ? "Trouvez un séjour au soleil, au ski ou sportif pour vos vacances en famille, en groupe (CE, étudiant, colonie pour enfants et ados) en France ou à l&#039;étranger" : "Holidays in France and oll over the world, ski packages, sports events, summer camps, customized trip for groups, students or professionals +33 4 67 58 36 36" : this.props.description,
            'og:site_name': 'VTR-Voyages',
            'twitter:title': this.props.title === "" ? this.props.language.langue === "fr" ? "VTR Voyages - vacances au soleil, à la mer, séjours de ski à la montagne" : "VTR Voyages - Travel Agency : ski holidays, sun and many other activites" : this.props.title,
        }
      }
    }

    return (
      <DocumentMeta {...meta} />
    )
  }
}

export default connect(mapStateToProps)(Meta)
