import React from 'react'
import { connect } from 'react-redux'
import './promotions.css'
import LazyLoad from 'react-lazy-load';
import { getImageAWS } from '../../../API/utils'
import { MDBCol, MDBRow } from 'mdbreact'

const mapStateToProps = (state) => {
  return {
    menu : state.menu,
    language : state.language,
    sejour : state.sejour,
    home : state.home
  }
}

class PromotionsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { dontDisplay : false }
  }

  loadPromotions() {
    let i = 0
    let promotion = []
    let row = []

    let data = []

    if (this.props.sejour.canShow) {
      data = this.props.sejour.promotions
    }
    else {
      data = this.props.home.promotions
    }

    for (let promo of data) {
      let promo_percent = Math.ceil(100 - (promo._source.promo_price * 100 / promo._source.min_price))
      if (promo_percent > 10 && promo_percent < 90) {
        if (i === 0 || i === 5) {
          promotion.push(
            <MDBCol key={i} className="spaceBetweenColumns" xs="12" sm="12" md="7" lg="7" xl="7">
            <a href={promo._source.link}>
            <div className="specialContainer">
            <LazyLoad height={250} offsetVertical={1000} debounce={false}>
            <img alt={promo._source.title.fr} src={getImageAWS(promo._source, 500)} className="promotionsImageHeight"/>
            </LazyLoad>
            <noscript>
            <style>{`.lazyload-placeholder { display: none; }`}</style>
            <img alt={promo._source.title.fr} src={getImageAWS(promo._source, 500)} className="promotionsImageHeight"/>
            </noscript>
            <a href={promo._source.link}><div className="longVignette">{promo._source.title.fr + ", " + promo._source.localisation.ville}</div></a>
            <div className="promotionSquare">{promo_percent}%</div>
            </div>
            </a>
            </MDBCol>
          )
        }
        else if (i === 1 || i === 6) {
          promotion.push(
            <MDBCol key={i} xs="12" sm="12" md="5" lg="5" xl="5">
            <a href={promo._source.link}>
            <div className="specialContainer">
            <LazyLoad height={250} offsetVertical={1000} debounce={false}>
            <img alt={promo._source.title.fr} src={getImageAWS(promo._source, 500)} className="promotionsImageHeight"/>
            </LazyLoad>
            <noscript>
            <style>{`.lazyload-placeholder { display: none; }`}</style>
            <img alt={promo._source.title.fr} src={getImageAWS(promo._source, 500)} className="promotionsImageHeight"/>
            </noscript>
            <a href={promo._source.link}><div className="longVignette">{promo._source.title.fr + ", " + promo._source.localisation.ville}</div></a>
            <div className="promotionSquare">{promo_percent}%</div>
            </div>
            </a>
            </MDBCol>
          )
        }
        else {
          promotion.push(
            <MDBCol key={i} className="spaceBetweenColumns" xs="12" sm="12" md="4" lg="4" xl="4">
            <a href={promo._source.link}>
            <div className="specialContainer">
            <LazyLoad height={250} offsetVertical={1000} debounce={false}>
            <img alt={promo._source.title.fr} src={promo._source.miniature.replace('324', '500')} className="promotionsImageHeight"/>
            </LazyLoad>
            <noscript>
            <style>{`.lazyload-placeholder { display: none; }`}</style>
            <img alt={promo._source.title.fr} src={promo._source.miniature.replace('324', '500')} className="promotionsImageHeight"/>
            </noscript>
            <a href={promo._source.link}><div className="longVignette">{promo._source.title.fr + ", " + promo._source.localisation.ville}</div></a>
            <div className="promotionSquare">{promo_percent}%</div>
            </div>
            </a>
            </MDBCol>
          )
        }

        if (i === 1) {
          row.push(
            <MDBRow key={i * 10}>
            {promotion}
            </MDBRow>
          )
          promotion = []
        }
        if (i === 4 || i === 6) {
          row.push(
            <MDBRow key={i * 10} className="addSpacingImage">
            {promotion}
            </MDBRow>
          )
          promotion = []
        }
        i++
      }
    }
    if (row.length === 0  && this.state.dontDisplay === false) {
      this.setState({dontDisplay : true})
    }
    if (row.length > 0 && this.state.dontDisplay === true) {
      this.setState({dontDisplay : false})
    }
    return row
  }

  render() {
    return (
      <div style={{marginTop : "20px"}} className={this.state.dontDisplay === true ? "notDisplay" : "mt-5"}>
      {
        this.props.sejour.promotions.length > 0 || this.props.home.promotions.length > 0 ?
        <React.Fragment>
        <span className="Title">{this.props.language.langue === "fr" ? "Promotions" : "Promotions"}</span>
        <hr/>
        {this.loadPromotions()}
        </React.Fragment>
        : ""
      }
      </div>
    )
  }
}

export default connect(mapStateToProps)(PromotionsComponent)
