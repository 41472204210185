import React from 'react'
import { JSONLD, Generic, AggregateRating } from 'react-structured-data';
import logo from '../../../assets/img/logo-vtr-voyages.png'

class JsonLdOrganisation extends React.Component {
  render() {
    return (
      <JSONLD dangerouslyExposeHtml={true}>
        <Generic type="organization" jsonldtype="Organization" schema={{name : "VTR Voyages", url : "vtr-voyages.fr", logo : logo, sameAs : [ "https://www.facebook.com/VtrVoyages", "https://twitter.com/vtrvoyages", "https://fr.pinterest.com/vtrvoyage/", "https://www.youtube.com/channel/UCx2j3sg6sW3P0_K2m1EcUvQ", "https://www.instagram.com/vtrvoyages_fr/", "https://www.linkedin.com/company/vtr-voyages/", "https://blog.vtr-voyages.fr/"]}}>
          <AggregateRating ratingValue={4.454} reviewCount={2414}/>
        </Generic>
      </JSONLD>
    )
  }
}

export default JsonLdOrganisation
