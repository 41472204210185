import React from 'react'
import Carousel from '../carousel/carousel'
import OnVousEmmene from './on-vous-emmene/on-vous-emmene'
import Populaires from './les-plus-populaires/populaires'
import LastMinutes from './derniere-minutes/derniere-minutes'
import Search from '../sejour/search/search'
import SEOText from '../sejour/SEOText/seoText'
import PromotionsComponent from '../sejour/promotions/promotions'
import { connect } from 'react-redux'
import { loadHomeData } from '../../reducers/home'
import JsonLdOrganisation from '../jsonLd/organisation/jsonLdOrganisation'
import Features from './features/features'
import Meta from '../meta/meta'
import CoupsCoeur from './coups-coeur/coups-coeur'
import { MDBContainer } from 'mdbreact'
import DemandeDevisStation from "./demande-devis-station/demandeDevisStation";

const mapStateToProps = (state) => {
  return {
    language : state.language
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadHome: () => {
      dispatch(loadHomeData())
    }
  }
}

export class Home extends React.Component {
  constructor(props) {
    super (props)
    if (typeof document !== "undefined") {
      if (window.location.hostname === "localhost") {
        this.props.loadHome()
      }
    }
  }

  render() {
    let sitelinksFr = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": "https://www.vtr-voyages.fr/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://vtr-voyages.fr/recherche?recherche={search_term_string}",
        "query-input": "required name=search_term_string"
      }
    }
    let sitelinksEn = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": "https://www.vtr-voyages.com/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://vtr-voyages.com/recherche?recherche={search_term_string}",
        "query-input": "required name=search_term_string"
      }
    }
    return (
      <React.Fragment>
        <Meta location={this.props.location} title={this.props.language.langue === "fr" ? "VTR Voyages - vacances au soleil, à la mer, séjours de ski à la montagne" : "VTR Voyages - Travel Agency : ski holidays, sun and many other activites"} description={this.props.language.langue === "fr" ? "Trouvez un séjour au soleil, au ski ou sportif pour vos vacances en famille, en groupe (CE, étudiant, colonie pour enfants et ados) en France ou à l'étranger" : "Holidays in France and oll over the world, ski packages, sports events, summer camps, customized trip for groups, students or professionals +33 467 58 36 36"}/>
        <JsonLdOrganisation/>
        {
          this.props.language.langue === "fr" ?
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(sitelinksFr) }}></script>
          :
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(sitelinksEn) }}></script>
        }
        
        <Carousel/>
        <Search home={true}/>
        
        <MDBContainer>
          <OnVousEmmene/>
        </MDBContainer>
          <Features/>
        <MDBContainer>
          <DemandeDevisStation/>
          <CoupsCoeur/>
          <LastMinutes/>
          <Populaires/>
          <PromotionsComponent/>
          <SEOText homePage={true}/>
        </MDBContainer>
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
