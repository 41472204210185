import { MDBTooltip } from 'mdbreact'
import React from 'react'


export function getImageAWS (source, size) {
    let metadata = source.miniature.match(/\w+-width-\d+-\d+.jpeg/);
    if (metadata) {
      metadata = metadata[0].split('-');
      if (metadata[0] === "various")
        metadata[0] = "product";
      source.miniature = `${process.env.REACT_APP_AWS_CDN}/${metadata[0]}/${size}x-1/${metadata[3]}.${metadata[4]}`;
    }
    return source.miniature
}

export function loadPicto(picto, langue = "fr", limit = 10, space = false) {
  let details = []
  let i = 1

  try {
    for (let icon of picto) {
      if (icon.description.fr !== "") {
        details.push(

          <MDBTooltip key={i}  domElement tag="p" placement="bottom">
              <i alt={langue === "fr" ? icon.description.fr : icon.description.en} className={icon.icon}>{space ? <span>&nbsp;</span> : ""}</i>
              <p >{langue === "fr" ? icon.description.fr : icon.description.en}</p>
          </MDBTooltip>
        )
      }
      i++
    }
    return details.slice(0, limit)
  }
  catch(e) {
    console.log(e)
    return <div></div>
  }
}