import React from "react";
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView } from "mdbreact";
import './carousel.css'
import {connect} from "react-redux";
import LazyLoad from 'react-lazy-load';

const mapStateToProps = (state) => {
  return {
    sejour : state.sejour,
    language : state.language,
  }
}

function importAll(r) {
  let images = {};
  // eslint-disable-next-line
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

// eslint-disable-next-line
const images = importAll(require.context('../../assets/img', false, /\.(png|jpe?g|svg)$/));

class Carousel extends React.Component {
  generateSlide = () => {
    let slider = []
    let i = 1
    let j = 1

    for (let slide of this.props.sejour.carousel) {
      slide = slide._source
      slider.push(
        <a key={i} title={slide.name} href={slide.link_button}>
          <MDBCarouselItem className="cursorPointer" itemId={j}>
            <MDBView>
              <LazyLoad offsetRight={100} offsetLeft={100} debounce={false}  throttle={1000}>
                {
                  slide.import ?
                  <img className="cursor-pointer d-block imageSlider w-100 h-100" src={images[slide.link]} alt={slide.name} />
                  :
                  <img className="cursor-pointer d-block imageSlider w-100 h-100" src={slide.link} alt={slide.name} />
                }
              </LazyLoad>
            </MDBView>
          </MDBCarouselItem>
        </a>
      )
      j++
      i++
    }

    if (this.props.special) {
      slider = []
      slider.push(
        <MDBCarouselItem key={1} className="cursorPointer" itemId={1}>
          <MDBView>
            <LazyLoad offsetRight={100} offsetLeft={100} debounce={false}  throttle={1000}>
              <img className="d-block imageSlider w-100 h-100" src={images[this.props.data]} alt={this.props.alt} />
            </LazyLoad>
          </MDBView>
        </MDBCarouselItem>
      )

      return (
        <div id="carousel">
          <MDBCarousel activeItem={1} length={1} showControls={true} showIndicators={true} className="z-depth-1 Carousel ">
            <MDBCarouselInner>
              {slider}
            </MDBCarouselInner>
          </MDBCarousel>
        </div>
      )
    }
    return (
      <div id="carousel">
        <MDBCarousel activeItem={1} length={j - 1} showControls={true} showIndicators={true} className="z-depth-1 Carousel ">
          <MDBCarouselInner>
            {slider}
          </MDBCarouselInner>
        </MDBCarousel>
      </div>
    )
  }

  render() {
      return this.generateSlide()
  }
}

export default connect(mapStateToProps)(Carousel)
