import React from 'react'
import {MDBBtn, MDBCol, MDBRow} from "mdbreact";
import {connect} from 'react-redux'
/*import clubVac from '../../../assets/img/club-vacances-ski-.jpg'*/

const mapStateToProps = (state) => {
    return {
        language : state.language,
        home : state.home
    }
}
const DemandeDevisStation = (state) => {

    return (
        <div id="demandeDeDevisStation" className={'mb-5'}>

            <MDBRow className="bg-image w-100 m-0"
                    style={{
                        minHeight: "600px",
                        objectFit: "cover",
                        backgroundPosition: "center",
                        backgroundImage: "url('https://images.unsplash.com/photo-1551524559-8af4e6624178?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1626&q=80')",
                        height: "50vh"
                    }}>

                <MDBCol
                        sm={"7"}
                        className={"d-flex flex-column justify-content-center align-items-center p-sm-5 p-2"}>
                    <div className={"mb-5"}>
                        <p className="Title">{state.language.langue === "fr" ? "Demander un devis sur-mesure" : "Quote request"}</p>
                        <hr />
                        <h5><strong>Suivez notre guide pour trouver la station de ski qui vous correspond le mieux.</strong></h5>
                        <p className={'mt-3 text-justify'}  style={{whiteSpace: "pre-line"}}>
                            Cela est simple, rapide et gratuit, il vous suffit de répondre à quelques questions et
                            nous vous recontacterons pour vous proposer un devis sur-mesure.
                        </p>

                    </div>

                    <MDBBtn href={"/page/devis-indiv"} color="info" size="xl" className="ml-0 mt-0">
                        {state.language.langue === "fr" ? "Aidez moi a trouver une station" : "Individual quote request"}
                    </MDBBtn>

                </MDBCol>

            </MDBRow>
        </div>
    )
}

export default connect(mapStateToProps)(DemandeDevisStation)