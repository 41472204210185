import Autosuggest from 'react-autosuggest'
import React from 'react'
import API from '../../../API/api'
import axios from 'axios'
import './search.css'

const getSuggestionValue = suggestion => suggestion.name;

class SuggestionVille extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.destination || '',
            suggestions: []
        };
    }

    onSubmit = (e) => {
        e.preventDefault()
    }

    renderSuggestion = (suggestion) => {
        console.log(suggestion)
        let domaine = null
        if (suggestion.agg2.buckets[0].agg3.buckets.length !== 0) {
            if ( typeof suggestion.agg2.buckets[0].agg3 !== "undefined") {
                if (typeof suggestion.agg2.buckets[0].agg3.buckets[0].agg4 !== "undefined") {
                    if (suggestion.agg2.buckets[0].agg3.buckets[0].agg4.buckets.length !== 0) {
                        domaine = suggestion.agg2.buckets[0].agg3.buckets[0].agg4.buckets[0].key
                    }
                }
            }
        }
        return (
            
            <div>
                    <div className="d-flex" onClick={(e) => {
                        console.log(suggestion.key + (suggestion.agg2.buckets[0].key !== suggestion.key ? ( ' (' + suggestion.agg2.buckets[0].key + ')') : ""));
                        this.props.updateData({key : "destination=", value : suggestion.key})
                        this.setState({query : suggestion.key, value : suggestion.key + (suggestion.agg2.buckets[0].key !== suggestion.key ? ( ' (' + suggestion.agg2.buckets[0].key + ')') : "")});
                        e.preventDefault()
                        }}>
                        <div className="ml-2 d-inline">
                            <p className="mb-0"><i style={{color : "#005c95"}}className="fas fa-map-marker-alt"></i> {suggestion.key}</p>
                            <small style={{color : "grey"}} className="mb-0">{suggestion.agg2.buckets[0].key !== suggestion.key ? suggestion.agg2.buckets[0].key : ""}</small>
                            <br/>
                            <small style={{color : "grey"}}>{domaine !== null ? (domaine) : ""}</small>
                        </div>
                    </div>
                    <hr className="mb-0 mt-0"/>
            </div>
        )
    }

    renderInputComponent = (inputProps) => {
        const props = {
            ...inputProps,
            className: inputProps.className + ' '
        }
        return (
            <div ref={this.myRef} className="h-100" style={{borderRadius: "2px", border: "1px solid #dbdbdb"}}>
            <form onSubmit={this.onSubmit}>
                <div className="d-flex align-items-center w-100 suggest-ville-input bg-white">
                    <i className="ml-2 fas fa-map-signs fa-lg" style={{color: '#009ee0'}} />&nbsp;&nbsp;
                    <input {...props} />
                </div>
            </form>
            {this.state.loading && (<div/>)}
            </div>
        )
    }

    onChange = (event, { newValue }) => {
        if (typeof newValue !== "undefined") {
            this.setState({
                value: newValue
            });
        }
    };

    onSuggestionsFetchRequested = async ({ value }) => {
        let query = {
            "query":{
               "multi_match" : {
               "query":    value, 
               "type":       "bool_prefix",
               "fields": [ "localisation.ville", "localisation.station", "localisation.domaine^10", "localisation.pays",  "localisation.region",] 
             }
            },
            "aggs": {
             "agg1": {
               "terms": {
                 "field": "localisation.ville.raw"
               },
               "aggs": {
                 "agg2": {
                   "terms": {
                     "field": "localisation.region.raw"
                   },
                   "aggs": {
                     "agg3": {
                       "terms": {
                         "field": "localisation.station.raw"
                       },
                       "aggs": {
                        "agg4": {
                          "terms": {
                            "field": "localisation.domaine.raw"
                          }
                        }
                      }
                     },
                   }          
                 }
               }
             }
           },
            "size":0,
            "_source":[
              
               "localisation"
              
            ]
         }
        let api = new API()
        let config = api.getAxiosConfig()
        this.axios = axios.create(config)
        let res = await this.axios.post(process.env.REACT_APP_ELASTIC_URL + "/_search?rest_total_hits_as_int=true", query)
        let suggestions = res.data.aggregations.agg1.buckets

        this.setState({ suggestions: suggestions, query : value });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };


    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "Destination",
            value,
            onChange: this.onChange,
        };

        return (
                <div>
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        renderSuggestion={this.renderSuggestion}
                        getSuggestionValue={getSuggestionValue}
                        renderInputComponent={this.renderInputComponent}
                        inputProps={inputProps} />
                </div>
        );
    }
}

export default SuggestionVille