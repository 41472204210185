import React from 'react'
import {connect} from "react-redux";
import Metier from '../../../API/metier'
import { isMobile } from "react-device-detect";
import LazyLoad from 'react-lazy-load';
import './coups-coeur.css'
import { MDBCol, MDBRow,  MDBBadge, MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText } from "mdbreact";
import { transformTypeSejour } from '../../recherche/star_and_icon';
import { getImageAWS } from '../../../API/utils'

const mapStateToProps = (state) => {
    return {
        language : state.language,
        home : state.home
    }
}

class CoupsCoeur extends React.Component {
    loadCard = () => {
        let card = []
        let i = 1
        let metier = new Metier()
        let price

        for (let newCard of this.props.home.coupsCoeur) {
            try {
                price = newCard._source.min_price

                card.push(
                    <MDBCol key={i} md="4" sm="6" lg="3" xl="3">
                        <MDBCard narrow ecommerce className="setHeightCardPromotion mb-2">
                            {
                                newCard._source.promo_price < newCard._source.min_price && newCard._source.promo_price !== 0 ?
                                    <MDBBadge className="setBadgePromotion" color="danger">-{Math.ceil(100 - (100 * newCard._source.promo_price / newCard._source.min_price))}%</MDBBadge>
                                    : <br/>
                            }
                            <a href={newCard._source.link}>
                                <LazyLoad offsetRight={2000} offsetVertical={500} debounce={true} height={200}>
                                    <MDBCardImage cascade top className="setPropertyPromotionImage" src={getImageAWS(newCard._source, 324)} alt={newCard._source.title.fr}/>
                                </LazyLoad>
                            </a>
                            <noscript>
                                <style>{`.lazyload-placeholder { display: none; }`}</style>
                                <MDBCardImage cascade top className="setPropertyPromotionImage" src={getImageAWS(newCard._source, 324)} alt={newCard._source.title.fr}/>
                            </noscript>
                            <MDBCardBody cascade>
                                <a title={newCard._source.type_sejour + " " + newCard._source.title.fr} href={"/" + newCard._source.type_sejour} className="text-muted">
                                    <p>{transformTypeSejour(newCard._source.type_sejour)}</p>
                                </a>
                                <MDBCardTitle tag="div">
                                    <strong>
                                        <a href={newCard._source.link}>{newCard._source.title.fr}</a>
                                    </strong>
                                </MDBCardTitle>
                                <MDBCardText>{newCard._source.localisation.ville}</MDBCardText>
                            </MDBCardBody>
                            <hr/>
                            <MDBRow className="setPaddingPricePromo">
                                <MDBCol md="6">
                                    {
                                        newCard._source.promo_price < newCard._source.min_price && newCard._source.promo_price !== 0 ?
                                            <React.Fragment>
                                                <span>{this.props.language.langue === "fr" ? "dès" : "from"} </span><span className="setSizePricePromo">{newCard._source.type_sejour === "colonies-de-vacances" || newCard._source.type_sejour === "sejours-sportifs" ?  price : Math.ceil(newCard._source.promo_price)} € </span><span>{newCard._source.type_sejour === "colonies-de-vacances" || newCard._source.type_sejour === "sejours-sportifs" ? "/ personne" : "/ log"}</span><br/>
                                                <span>{this.props.language.langue === "fr" ? "au lieu de" : "instead of"} </span><span className="setSizeOfOldPricePromotion">{Math.ceil(newCard._source.min_price)} €</span>
                                            </React.Fragment>
                                            :
                                            newCard._source.type_sejour === "colonies-de-vacances" || newCard._source.type_sejour === "sejours-sportifs" || newCard._source.type_sejour === "sejours-evenements" ? "" :
                                                <React.Fragment>
                                                    <span>dès </span><span className="setSizePricePromo">{newCard._source.type_sejour === "colonies-de-vacances" || newCard._source.type_sejour === "sejours-sportifs" ?  price : Math.ceil(newCard._source.min_price)} € </span><span>{newCard._source.type_sejour === "colonies-de-vacances" || newCard._source.type_sejour === "sejours-sportifs" ? "/ personne" : "/ log"}</span><br/>
                                                </React.Fragment>
                                    }
                                </MDBCol>
                                <MDBCol xs="6" md="6">
                                    <span>{newCard._source.type_sejour === "colonies-de-vacances" || newCard._source.type_sejour === "sejours-sportifs" ? "" : this.props.language.langue === "fr" ? "Hébergement seul" : "Accommodation only"}</span><br/>
                                    <span>{this.props.language.langue === "fr" ? "Départ le" : "Departure on"} {metier.GetFormattedDate(new Date(newCard._source.min_price_dates.from))}</span>
                                </MDBCol>
                            </MDBRow>
                        </MDBCard>
                    </MDBCol>
                )
            }
            catch (e) {
                console.log(e)
            }

            i++
        }

        return card
    }

    render () {
        if (isMobile || this.props.language.mobile) {
            return <div></div>
        }

        return (
            <>
                {this.props.home.coupsCoeur.length > 0 && (
                    <div id="coupsCoeur">
                        <p className="Title">{this.props.language.langue === "fr" ? "Coups de coeur" : "Heart stroke"}</p>
                        <hr/>
                        <MDBRow>
                            {this.loadCard()}
                        </MDBRow>
                    </div>
                )}
            </>
        )
    }
}

export default connect(mapStateToProps)(CoupsCoeur)