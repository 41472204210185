import React from 'react'
import './search.css'
import NewSearch from './newSearch'

export class Search extends React.Component {
  render() {
    return <NewSearch page={this.props.page} home={this.props.home}/>
  }
}

export default (Search)