import React from 'react'
import { MDBIcon } from "mdbreact";
import { MDBTooltip } from 'mdbreact'


export const generateStar = (star) => {
  if (star > 5) {
    star = star / 2
  }
  if (star > 5) star = 5
  let how_much = star
  let start = []
  let i = 0

  while (i < how_much) {
    start.push(
      <React.Fragment key={i}>
        <i style={{color : "#ffc14f"}} className="fa fa-heart" aria-hidden="true">{i + 1 !== how_much ? "\t " : ""}</i>
      </React.Fragment>
    )
    i++
  }
  start.push(
    <br key={9}/>
  )
  return start
}

export const generateStarASCII = (star) => {
  if (star > 5) {
    star = star / 2
  }
  if (star > 5) return
  let how_much = star
  let start = []
  let i = 0

  while (i < how_much) {
    start.push(
      <React.Fragment key={i}>
        <span>*</span>
      </React.Fragment>
    )
    i++
  }
  return start
}

export const generateGoodFeedBack = (star, source) => {
  if (source.type_sejour === "colonies-de-vacances") {
    return ""
  }
  if (star > 5) {
    star = star / 2
  }

  if (star > 10) {
    return (
      <MDBTooltip  domElement tag="p" placement="bottom">
        <span style={{color : "#ffc14f"}}>&nbsp;<MDBIcon size="lg" className="mb-1" icon="thumbs-up" /></span>
        <p>Cet hébergement fait partie des mieux notés par nos clients</p>
      </MDBTooltip>
      )
  }
  return ""
}

export const generateStarGold = (star) => {
  if (star > 5) {
    star = star / 2
  }
  if (star > 5) return
  let how_much = star
  let start = []
  let i = 0

  while (i < how_much) {
    start.push(
      <React.Fragment key={i}>
         <i style={{color : "#ffc14f"}} className="fa fa-star" aria-hidden="true"></i>
      </React.Fragment>
    )
    i++
  }
  start.push(
    <br key={9}/>
  )
  return start
}

export const transformTypeSejour = (type_sejour) => {
  if (type_sejour === "sejours-ski") {
    return "Séjours ski"
  }
  else if (type_sejour === "sejours-france") {
    return "Séjours France"
  }
  else if (type_sejour === "sejours-sportifs") {
    return "Séjours Sportifs"
  }
  else if (type_sejour === "sejours-monde") {
    return "Séjours Monde"
  }
  else if (type_sejour === "colonies-de-vacances") {
    return "Colonies de vacances"
  }
  return type_sejour
}
